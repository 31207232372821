.kumbh-gallery-hero {
  position: relative;
  width: 100%;
  height: 100vh;
}

.kumbh-gallery-background-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: background 1s ease-in-out;
  z-index: 1;
}

.kumbh-gallery-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  color: white;
  padding: 20px;
}

.kumbh-gallery-hero-overlay h1 {
  text-transform: uppercase;
  font-size: 3rem; /* Adjust for responsiveness */
  font-weight: bold;
  margin: 0;
}

.kumbh-gallery-hero-overlay h4 span {
  text-transform: capitalize;
}

.kumbh-gallery-hero-overlay h1 span {
  color: #ee8c26;
}

.kumbh-gallery-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
  justify-content: center;
  padding: 40px 20px;
}

.gallery-thumbnail {
  width: 350px;
  height: 200px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s;
  border-radius: 10px;
}

.gallery-thumbnail:hover {
  transform: scale(1.05);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  /* position: relative; */
  width: 80%;
  max-width: 900px;
}

.modal-image {
  max-width: 800px;
  width: 100%;
  height: 80%;
  border-radius: 8px;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 40px;
  background: none;
  color: white;
  font-size: 30px;
  border: none;
  cursor: pointer;
}

.modal-prev,
.modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  color: white;
  font-size: 40px;
  border: none;
  cursor: pointer;
}

.modal-prev {
  left: 20px;
}

.modal-next {
  right: 20px;
}

/* Responsive */
@media (max-width: 768px) {
  .kumbh-gallery-hero-overlay h1 {
    font-size: 30px;
  }

  .kumbh-gallery-hero {
    height: 50vh;
  }

  .gallery-thumbnail {
    width: 200px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .kumbh-gallery-hero-overlay h1 {
    font-size: 25px;
  }

  .kumbh-gallery-hero-overlay h4 {
    font-size: 12px;
  }

  .kumbh-gallery-hero {
    height: 30vh;
  }

  .gallery-thumbnail {
    width: 100%;
    height: 180px;
  }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 0px 10px;
}

.modal-form-content {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
}

.modal-form-content form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: #fff;
}

.modal-form-content .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-form-content form input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    background-color: #E2E8F0;
    border: none;
    outline: none;
    border-radius: 5px;
}

.modal-form-content form select {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    background-color: #E2E8F0;
    border: none;
    outline: none;
    border-radius: 5px;
}

.modal-form-content form input::placeholder {
    font-weight: 600;
}

.modal-form-content form textarea::placeholder {
    font-weight: 600;
}

.modal-form-content form textarea {
    width: 100%;
    max-width: 100%;
    height: 100px;
    padding-top: 10px;
    padding-left: 10px;
    background-color: #E2E8F0;
    border: none;
    outline: none;
    border-radius: 5px;
}

.modal-form-content form button {
    padding: 10px 20px;
    background-color: #ee8c26;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
}


/* General styles for the hero section */
.hero-section {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: background 1s ease-in-out;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for text readability */
}

.hero-container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  height: 100%;
  padding: 0px 20px;
  margin-top: 100px;
  color: #fff;
}

.hero-container-left {
  width: 100%;
  line-height: 60px;
}

.hero-container-left h1 {
  margin: 0;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
}

.hero-container-left h1 span {
  display: inline-block;
  animation: slideInOut 8s ease-in-out infinite;
  color: #ff7722;
  white-space: nowrap;
  text-transform: uppercase;
}

/* Text Slide In & Out Animation */
@keyframes slideInOut {
  0% {
    transform: translateY(-50px); /* Start above */
    opacity: 0;
  }
  25% {
    transform: translateY(0); /* Move to normal position */
    opacity: 1;
  }
  50% {
    transform: translateY(0); /* Stay in place */
    opacity: 1;
  }
  75% {
    transform: translateY(50px); /* Slide below */
    opacity: 0;
  }
  100% {
    transform: translateY(50px); /* Stay below */
    opacity: 0;
  }
}

.search-destination-container {
  margin-top: 50px;
  position: relative;
}

.search-destination-search {
  max-width: 500px;
  background-color: #fff;
  padding: 10px 15px 10px 20px;
  border-radius: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search-destination-texts {
  height: 0px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.search-destination-texts span {
  margin-right: 10px; /* Space between the text and separator */
  font-size: 18px;
  font-weight: 550;
}

/* Color for first and third spans */
.search-destination-texts span:nth-child(1),
.search-destination-texts span:nth-child(3) {
  color: #ff7722; /* White color */
}

/* Color for second and last spans */
.search-destination-texts span:nth-child(2),
.search-destination-texts span:nth-child(4) {
  color: #fff; /* Orange color */
}

/* Add the '|' separator except for the last span */
.search-destination-texts span:not(:last-child)::after {
  content: "|";
  margin-left: 10px; /* Space after the separator */
  color: #fff; /* Color for the separator */
}

.search-destination-description {
  margin-left: 15px;
  font-weight: 450;
}

.search-destination-search input {
  border: none;
  outline: none;
  height: 100%;
  font-size: 16px;
}

.search-destination-search input::placeholder {
  font-weight: 550;
}

.search-destination-search button {
  background-color: #ff7722;
  color: #fff;
  border: none;
  outline: none;
  padding: 10px 40px;
  border-radius: 55px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 550;
}

.suggestions-list {
  list-style: none;
  left: 10px;
  top: 110px;
  background-color: #fff;
  color: #000;
  overflow-y: hidden;
  position: absolute;
  width: 200px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.suggestions-list li {
  height: fit-content;
  cursor: pointer;
  padding-left: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.suggestions-list li:hover {
  background-color: #ff7722;
  color: #fff;
}

/* Responsive Design */
@media (max-width: 480px) {
  .hero-section {
    height: 80vh;
  }

  .hero-container {
    flex-direction: column;
    text-align: center;
    padding: 0px 10px;
    margin-top: 50px;
  }

  .hero-container-left h1 {
    width: 320px;
    text-align: center;
    font-size: 25px;
    height: 40px;
    text-transform: uppercase;
  }

  .hero-container-left h1 span {
    height: 40px;
  }

  .search-destination-container {
    padding-top: 50px;
  }

  .search-destination-texts {
    align-items: center;
    text-align: center;
  }

  .search-destination-texts span {
    font-size: 12px;
  }

  .search-destination-description {
    font-size: 12px;
    text-align: center;
  }

  .search-destination-search {
    border-radius: 10px;
    flex-direction: column;
    gap: 20px;
    padding: 15px 10px;
  }

  .search-destination-search input {
    width: 100%;
  }

  .search-destination-search button {
    width: 100%;
    border-radius: 5px;
  }

  .suggestions-list {
    top: 150px;
    left: 0px;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
  }

  .suggestions-list li {
    height: 50px;
    text-align: start;
    /* border-bottom: 2px solid #000; */
    padding-left: 20px;
  }
}
